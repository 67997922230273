import { type ChangeEvent, type KeyboardEvent, type ReactNode, type SyntheticEvent } from 'react';
import * as React from 'react';
import Downshift, { type GetItemPropsOptions } from 'downshift';

import { Decorators } from './components/Decorators/Decorators';
import { Dropdown } from '../../Dropdown';
import { DropdownOption } from './components/DropdownOption/DropdownOption';
import { ErrorMessage } from '../../ErrorMessage';
import { Field } from '../Field/Field';
import { FormLabel } from '../FormLabel/FormLabel';
import { InputBorderContainer } from '../InputBorderContainer/InputBorderContainer';
import { AnimatedPlaceholderContainer } from '../AnimatedPlaceholderContainer/AnimatedPlaceholderContainer';
import { TextInputContainer } from '../TextInputContainer/TextInputContainer';
import { SIZES, DIRECTIONS } from '../constants';

import styles from './BaseSelect.scss';

import {
    type CommonSelectProps,
    type CommonSelectState,
    type SelectOption,
    type SingleOnChangeArgs,
    type DropdownArrowClickLifecycle,
} from './sharedTypes';

export type CommonSingleSelectProps = CommonSelectProps & {
    onBlur?: (option: SingleOnChangeArgs) => void;
    onChange: (option: SingleOnChangeArgs) => void;
    onFocus?: () => void;
    placeholder?: string;
    value: string | undefined;
    inputRefCallback?: (input: HTMLSelectElement | HTMLInputElement) => void;
};

type BaseSelectProps = CommonSingleSelectProps & {
    customDropdownOption?: (arg0: {
        closeMenu: () => void;
        filteredOptions: SelectOption[];
        getItemProps: (options: GetItemPropsOptions<SelectOption>) => Record<string, $TSFixMe>; // downshift props
        inputValue: string;
        isLoading: boolean | undefined;
        name: string;
    }) => React.ReactNode;
    maskForPrivacy?: boolean;
    searchable?: boolean | null;
    dropdownDetachedMode?: boolean;
};

type BaseSelectState = CommonSelectState & {
    initialSelection: SelectOption | null;
    selection: SelectOption | null;
};

const getSelectionFromValue = (
    value: string | undefined,
    options: SelectOption[]
): SelectOption | null => {
    return value ? options.find(option => option.value === value) || null : null;
};

export class BaseSelect extends React.Component<BaseSelectProps, BaseSelectState> {
    static defaultProps = {
        autoFocus: false,
        clearable: true,
        filterResults: true,
        errorMessageAlignment: DIRECTIONS.left,
        hasAnimatedPlaceholder: false,
        hasError: false,
        horizontalSpacing: SIZES.def,
    };

    constructor(props: BaseSelectProps) {
        super(props);
        const selection = getSelectionFromValue(props.value, props.options);
        this.state = {
            isFocused: !!props.autoFocus,
            inputValue: selection ? selection.label : '',
            initialSelection: selection,
            selection,
        };
    }

    static getDerivedStateFromProps(
        nextProps: BaseSelectProps,
        prevState: BaseSelectState
    ): BaseSelectState {
        const selection = getSelectionFromValue(nextProps.value, nextProps.options);
        const oldSelection = prevState.selection;
        let inputValue = prevState.inputValue;
        if (selection !== oldSelection) {
            // controlled input change
            inputValue = selection ? selection.label : '';
        }
        return {
            ...prevState,
            inputValue,
            selection,
        };
    }

    componentDidMount(): void {
        // initial call to focus element if autoFocus is passed in as true

        /* istanbul ignore if */
        if (this.props.autoFocus) {
            this.focusInputNode();
        }
    }

    dropdownArrowClickLifecycle: DropdownArrowClickLifecycle = 'none';
    inputNode: { current: HTMLInputElement | null } = React.createRef();

    filterOptions = (): SelectOption[] => {
        const { options = [] } = this.props;
        const { selection, inputValue } = this.state;

        // Show all options if the input matches the current selection
        if (selection && selection.label.toLowerCase() === inputValue.toLowerCase()) {
            return options;
        }
        return options.filter((option: SelectOption): boolean => {
            const compareString = option.filterString || option.label;
            return (
                !inputValue ||
                (!!compareString && compareString.toLowerCase().includes(inputValue.toLowerCase()))
            );
        });
    };

    getEventOptionParam = (option: SelectOption | null): SingleOnChangeArgs => {
        const label = (option && option.label) || '';
        const value = (option && option.value) || '';

        const { name } = this.props;

        if (label && value) {
            return {
                label,
                name,
                value,
            };
        }
        return null;
    };

    handleBlurEvent = (isOpen: boolean, closeMenu: () => void): void => {
        const { onBlur } = this.props;
        const { selection } = this.state;
        let inputValue = '';
        if (selection) {
            inputValue = selection.label;
        }
        if (this.dropdownArrowClickLifecycle === 'mousedown') {
            this.dropdownArrowClickLifecycle = isOpen ? 'closing' : 'none';
        }

        closeMenu();
        this.setState({
            isFocused: false,
            inputValue,
            initialSelection: selection,
        });

        /* istanbul ignore else */
        if (onBlur) {
            onBlur(this.getEventOptionParam(selection));
        }

        // onInputChange call was removed from here,
        // see https://github.com/1stdibs/app-admin-trade/pull/1155 for more info
    };

    handleDecoratorClick = (e: SyntheticEvent<HTMLDivElement>): void => {
        if (this.dropdownArrowClickLifecycle === 'closing') {
            e.stopPropagation();
            this.focusInputNode();
        }
        this.dropdownArrowClickLifecycle = 'none';
    };

    handleDecoratorMousedown = (): void => {
        this.dropdownArrowClickLifecycle = 'mousedown';
    };

    handleClear = (closeMenu: () => void): void => {
        const { onInputChange, onChange, searchable } = this.props;

        onChange(null);

        if (onInputChange && searchable) {
            onInputChange('');
        }
        closeMenu();

        this.setState({ inputValue: '', selection: null });
    };

    handleChange = (
        option: SelectOption | null,
        preservedInputValue?: string,
        callback?: (() => void) | undefined
    ): void => {
        const { onInputChange, onChange, searchable } = this.props;

        if (!callback) {
            callback = () => {};
        }

        onChange(this.getEventOptionParam(option));

        let newInputValue;
        if (preservedInputValue) {
            newInputValue = preservedInputValue;
        } else {
            newInputValue = option ? option.label : '';
        }

        this.setState({ selection: option, inputValue: newInputValue }, callback);
        if (preservedInputValue && onInputChange && searchable) {
            onInputChange(preservedInputValue);
        }
    };

    handleFocusEvent = (): void => {
        const { isFocused } = this.state;
        const { onFocus } = this.props;

        if (!isFocused) {
            this.setState({ isFocused: true });
            if (typeof onFocus === 'function') {
                onFocus();
            }
        }
    };

    handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const { selection } = this.state;
        const { onInputChange, options, autoComplete, searchable } = this.props;

        const newValue = e.target ? e.target.value : '';

        this.setState({
            inputValue: newValue,
        });

        if (autoComplete) {
            const validOption = options.find(
                option => option.label.toLowerCase() === newValue.toLowerCase()
            );
            if (validOption) {
                this.handleChange(validOption);
                return;
            }
        }

        // If selection does not match the new input, clear it
        if (selection && selection.label.toLowerCase() !== newValue.toLowerCase()) {
            this.handleChange(null, newValue);
            return;
        }

        if (searchable && onInputChange) {
            onInputChange(newValue);
        }
    };

    handleKeydown = (e: KeyboardEvent<HTMLInputElement>): void => {
        switch (e.key) {
            case 'Esc':
            case 'Escape':
                this.handleChange(this.state.initialSelection, '', this.blurInputNode);
                break;
            case 'Enter':
                // Prevents enter keypresses without higlighting an item propagating to surrounding form
                e.preventDefault();
                this.blurInputNode();
                break;
            default:
                break;
        }
    };

    blurInputNode = (): void => {
        const inputNode = this.inputNode.current;
        if (inputNode) {
            inputNode.blur();
        }
    };

    focusInputNode = ({
        menuToggle,
    }: {
        menuToggle?: () => void;
    } = {}): void => {
        const inputNode = this.inputNode.current;
        if (inputNode && !this.props.disabled) {
            inputNode.focus();
            inputNode.selectionStart = 0;
            inputNode.selectionEnd = inputNode.value.length;
            if (menuToggle) {
                menuToggle();
            }
        }
    };

    itemToString = (item?: { label: string } | null): string => {
        if (item && item.label) {
            return item.label;
        }
        return '';
    };

    shouldShowAnimatedPlaceholder = (): boolean => {
        const { hasAnimatedPlaceholder, placeholder, searchable } = this.props;
        const { isFocused, inputValue, selection } = this.state;
        return (
            !!hasAnimatedPlaceholder &&
            !!placeholder &&
            (!!inputValue || (searchable && isFocused) || !!selection)
        );
    };

    render(): ReactNode {
        const {
            autoComplete,
            clearable,
            customDropdownOption,
            dataTn,
            disabled,
            dropdownDirection = 'below',
            errorMessage,
            errorMessageAlignment,
            horizontalSpacing,
            isLoading,
            label,
            maskForPrivacy,
            name,
            placeholder,
            searchable,
            size,
            filterResults,
            options = [],
            inputRefCallback,
            id: propId,
        } = this.props;

        const { isFocused, inputValue, selection } = this.state;

        const hasError = this.props.hasError || !!errorMessage;
        const errorDataTn = `${dataTn}-error`;

        const inputAutoComplete = autoComplete || 'off';
        const filteredOptions = filterResults ? this.filterOptions() : options;

        const dropdown = (
            direction: 'above' | 'below',
            isOpen: boolean,
            closeMenu: () => void,
            customOption: ReactNode,
            getItemProps: (options: GetItemPropsOptions<SelectOption>) => void,
            highlightedIndex: number | null,
            selectedItem: SelectOption | null
        ): ReactNode =>
            dropdownDirection === direction && (
                <Dropdown
                    dataTn={`${dataTn}-dropdown`}
                    hideTopBorder
                    isOpen={isOpen && (filteredOptions.length > 0 || !!customOption)}
                    matchContainerWidth
                    reverseDirection={direction === 'above'}
                    detachedMode={this.props.dropdownDetachedMode}
                >
                    <div className={styles.dropdownOptionsWrapper} style={{ maxHeight: 200 }}>
                        <div className={styles.filteredOptionsWrapper}>
                            {filteredOptions.map((item, index) => (
                                <DropdownOption
                                    // @ts-expect-error can't spread void, wrong type for getItemProps?
                                    {...getItemProps({
                                        item,
                                        onClick: () =>
                                            this.focusInputNode({
                                                menuToggle: closeMenu,
                                            }),
                                        onKeyDown: e => {
                                            switch (e.key) {
                                                case 'Spacebar':
                                                case ' ':
                                                case 'Enter':
                                                    this.focusInputNode({
                                                        menuToggle: closeMenu,
                                                    });
                                                    break;
                                                default:
                                                    break;
                                            }
                                        },
                                    })}
                                    key={`${item.value}-${index}`}
                                    dataTn={`${dataTn}-option-${index}`}
                                    isHighlighted={highlightedIndex === index}
                                    isSelected={!!selectedItem && selectedItem.value === item.value}
                                >
                                    {item.customDropdownDisplay || item.label}
                                </DropdownOption>
                            ))}
                        </div>
                        {customOption}
                    </div>
                </Dropdown>
            );
        return (
            <Downshift
                itemToString={this.itemToString}
                onChange={(option: SelectOption | null) => this.handleChange(option)}
                inputValue={inputValue}
                selectedItem={selection}
            >
                {({
                    closeMenu,
                    getInputProps,
                    getItemProps,
                    highlightedIndex,
                    isOpen,
                    openMenu,
                    selectedItem,
                }) => {
                    const spacing = horizontalSpacing === SIZES.small ? SIZES.small : SIZES.medium;

                    const customOption =
                        customDropdownOption &&
                        customDropdownOption({
                            getItemProps,
                            closeMenu: () => this.focusInputNode({ menuToggle: closeMenu }),
                            filteredOptions,
                            inputValue,
                            isLoading,
                            name,
                        });

                    let arrowDirection = isOpen ? DIRECTIONS.up : DIRECTIONS.down;
                    if (dropdownDirection === 'above') {
                        arrowDirection = isOpen ? DIRECTIONS.down : DIRECTIONS.up;
                    }
                    // Issues with Downshift `Callback` type
                    const animatedOnClick: $TSFixMe = disabled ? undefined : openMenu;

                    const inputId = propId || getInputProps().id;

                    // container base div is used by downshift. Do not remove.
                    return (
                        <div>
                            <Field>
                                <FormLabel htmlFor={inputId}>{label}</FormLabel>
                                {dropdown(
                                    'above',
                                    isOpen,
                                    closeMenu,
                                    customOption,
                                    getItemProps,
                                    highlightedIndex,
                                    selectedItem
                                )}
                                <InputBorderContainer
                                    disabled={disabled}
                                    hasError={hasError}
                                    isFocused={isFocused}
                                    onClick={() => this.focusInputNode({ menuToggle: openMenu })}
                                    size={size}
                                    maskForPrivacy={maskForPrivacy}
                                >
                                    <AnimatedPlaceholderContainer
                                        marginLeft={spacing}
                                        marginRight={spacing}
                                        onClick={animatedOnClick}
                                        placeholder={placeholder}
                                        showAnimatedPlaceholder={this.shouldShowAnimatedPlaceholder()}
                                    >
                                        {({ placeholderDidRender }) => (
                                            <TextInputContainer
                                                paddingTop={
                                                    placeholderDidRender
                                                        ? SIZES.animatedPlaceholder
                                                        : SIZES.none
                                                }
                                                usePaddingLeft={!!selection?.inputIconImage}
                                            >
                                                <input
                                                    {...getInputProps({
                                                        id: inputId,
                                                        placeholder: placeholderDidRender
                                                            ? ''
                                                            : placeholder,
                                                        autoComplete: inputAutoComplete,
                                                        autoFocus: isFocused,
                                                        disabled,
                                                        name,
                                                        onChange: this.handleInputChange,
                                                        onFocus: this.handleFocusEvent,
                                                        onBlur: () =>
                                                            this.handleBlurEvent(isOpen, closeMenu),
                                                        onKeyDown: this.handleKeydown,
                                                        readOnly: !searchable,
                                                        value: inputValue,
                                                        type: 'text',
                                                    })}
                                                    data-tn={`${dataTn}-input`}
                                                    ref={input => {
                                                        this.inputNode.current = input;
                                                        if (input && inputRefCallback) {
                                                            inputRefCallback(input);
                                                        }
                                                    }}
                                                />
                                                {selection?.inputIconImage && (
                                                    <img
                                                        className={styles.inputIconImage}
                                                        src={selection?.inputIconImage}
                                                        alt=""
                                                    />
                                                )}
                                            </TextInputContainer>
                                        )}
                                    </AnimatedPlaceholderContainer>
                                    <Decorators
                                        arrowDirection={arrowDirection}
                                        arrowPaddingRight={spacing}
                                        clearable={!!clearable}
                                        dataTn={dataTn}
                                        disabled={!!disabled}
                                        isLoading={!!isLoading}
                                        onClick={this.handleDecoratorClick}
                                        onMouseDown={this.handleDecoratorMousedown}
                                        onClear={() => this.handleClear(closeMenu)}
                                    />
                                </InputBorderContainer>
                                {dropdown(
                                    'below',
                                    isOpen,
                                    closeMenu,
                                    customOption,
                                    getItemProps,
                                    highlightedIndex,
                                    selectedItem
                                )}
                                <ErrorMessage
                                    message={errorMessage}
                                    dataTn={errorDataTn}
                                    alignRight={errorMessageAlignment === DIRECTIONS.right}
                                />
                            </Field>
                        </div>
                    );
                }}
            </Downshift>
        );
    }
}
